import unboxing from "../images/projects/unboxing.jpg";
import tefinancio from "../images/projects/tefinancio.jpg";
import hazzy from "../images/projects/hazzy.jpg";
import fio from "../images/projects/fio.jpg";

const projects = [
  {
    title: "UnboxingTCG",
    descriptions: [
      "Development of a system capable of trading, buying, and selling collectible trading card games, facilitating seamless transactions and robust user interactions.",
      "INCREASE of the scalability using the highly maintainable and testable microservice architecture. The architecture supports horizontal scaling, allowing the system to handle increased load and user demand efficiently.",
      "OPTIMIZATION of the system's performance by implementing advanced caching mechanisms and asynchronous processing.",
      "INTEGRATION of advanced search and filtering options to enable users to easily find and trade cards.",
      "COLLABORATION with cross-functional teams to deliver a feature-rich, user-friendly platform within tight deadlines.",
      "Tech-Stack: Next.js, TypeScript, Node.js, Express.js, MongoDB, Redis, Docker, Kubernetes, NATS Streaming Server, Nginx, Digital Ocean, Redis-based queue Bull, Microservices.",
    ],
    url: "#",
    img: unboxing,
  },
  {
    title: "Tefinancio",
    descriptions: [
      "Creation of an advanced system tailored for simulating car loans based on individual vehicle specifications.",
      "REDUCTION of the time required by Tefinancio to validate a client's eligibility for a car loan by 80%.",
      "IMPLEMENTATION of an intuitive user interface, allowing clients to effortlessly input vehicle details and receive loan simulations.",
      "OPTIMIZATION of the backend algorithms to ensure accurate and fast loan simulation calculations.",
      "Tech-Stack: React.js, Node.js, Express.js, MongoDB.",
    ],
    url: "https://tefinancio.com.ar",
    img: tefinancio,
  },
  {
    title: "Hazzy",
    descriptions: [
      "Creation of an innovative system empowering customers to effortlessly purchase products from the Hazzy Weekend Store using QR code scanning technology.",
      "REDUCTION of manual labor required for product purchases, streamlining the buying process for both customers and staff.",
      "EXPANSION of customer base by an impressive 70%, leveraging innovative QR code technology to attract and retain new patrons.",
      "IMPLEMENTATION of real-time inventory tracking, ensuring accurate product availability information for customers.",
      "OPTIMIZATION of QR code scanning functionality for seamless and efficient transactions.",
      "Tech-Stack: React.js, Node.js, Express.js, MongoDB.",
    ],
    url: "#",
    img: hazzy,
  },
  {
    title: "FIO+",
    descriptions: [
      "Development of a dynamic system showcasing personalized opportunities to clients based on their accumulated credits, enhancing engagement and satisfaction.",
      "IMPROVEMENT of communication channels between clients and administrators with a remarkable 100% increase in connectivity facilitated by a real-time chat feature.",
      "REDUCTION of the client's effort in searching for various opportunities across multiple platforms, consolidating information for easy access and convenience.",
      "COLLABORATION with stakeholders to gather feedback and iterate on system features, ensuring continuous improvement and alignment with user needs.",
      "Tech-Stack: Next.js, TypeScript, Node.js, Express.js, MongoDB, Socket.io.",
    ],
    url: "https://app.fiomicrocreditos.com/",
    img: fio,
  },
];

export default projects;
