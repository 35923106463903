import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BasicLayout from "../layouts/BasicLayout";
import ProjectsComponent from "../components/ProjectsComponent";
import Seo from "../components/seo";
import "./styles/resume.scss";

export default function Projects() {
  return (
    <BasicLayout>
      <Seo title="Projects" />
      <div className="body-projects">
        <Container>
          <Row>
            <Col xs={12}>
              <h1>Featured projects</h1>
            </Col>
          </Row>
          <ProjectsComponent />
        </Container>
      </div>
    </BasicLayout>
  );
}
