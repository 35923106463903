import React from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import projects from "../utils/projects"
import "./style.scss"

export default function ProjectsComponent() {
    return (
        <>
            {projects.map((projects, index) => (

                <Row key={index} className="projects-info">
                    <Col xs={12} md={6}>
                        <h2>{projects.title}</h2>
                        {projects.descriptions.map((description, index2) => (
                            <p key={index2}>{description}</p>
                        ))}
                    </Col>
                    <Col xs={12} md={6}>
                        {projects.url !== '#' ? (
                            <a href={projects.url} target="_blank" rel="noopener noreferrer">
                                <Image className="img" src={projects.img} />
                            </a>
                        ) : (
                            <a href={projects.url}>
                                <Image className="img" src={projects.img} />
                            </a>
                        )}
                    </Col>
                </Row>
            ))}
        </>
    )
}
